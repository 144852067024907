import React from 'react';
import { NavOnlyTemplate } from '@beelineloans/cx-library/src/components/layout/TemplateV2';
import Error from '@beelineloans/cx-library/src/components/Error';

const AccessDenied = () => (
  <NavOnlyTemplate>
    <Error code={403} />
  </NavOnlyTemplate>
);

export default AccessDenied;
